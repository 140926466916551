import { createEffect, createSignal, Show } from "solid-js";
import { produce } from "solid-js/store";
import { For } from "solid-js/web";
import { DEBUG_DATA, gotoResults, LOCAL_STORAGE_KEY, sendChanges, setSyncState, syncState } from "..";
import { CONFIG } from "../../config";
import type { Player, SavedGame, SaveGameRequest } from "../../types";
import { createPlayer } from "../Player";
import { makeAPICall } from "../api";
import GameSavedAlert from "../components/GameSavedAlert";
import { InfoTable } from "../components/InfoTable";
import { usePlayerData } from "../components/PlayerDataContext";
import { PlayerTable } from "../components/PlayerTable";
import { ResultsButton } from "../components/ResultsButton";
import { SyncStateDisplay } from "../components/SyncStateDisplay";
import { BTIcon } from "../components/bootstrap/BTIcon";
import { checkTrickAmount } from "../gameUtil";

const [savedUnderID, setSavedID] = createSignal<string>();
let saveGameAfterFinished = localStorage.getItem("save_to_db") !== null
    ? localStorage.getItem("save_to_db") === "true"
    : CONFIG.enableSavingByDefault;

export function App() {
    const [players, changePlayerData] = usePlayerData();
    const showResults = async () => {
        gotoResults();

        if (saveGameAfterFinished)
            saveGameToDB();
    };
    const saveGameToDB = async () => {
        const result = await makeAPICall("/games", "POST", {
            data: players,
            advertiseToViewers: syncState() !== "disabled"
        } satisfies SaveGameRequest);

        if (result.ok) {
            const savedGame: SavedGame = await result.json();
            setSavedID(savedGame.id);
        } else {
            setSavedID("");
        }
    }

    createEffect(() => {
        if (!DEBUG_DATA)
            // don't overwrite acutal save state if in debug mode
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(players));

        sendChanges(players);

        for (let i = 0; i < 12; i++)
            // check all games once after loading
            checkTrickAmount(i, players);
    });

    return (
        <>
            <div class="mt-2">
                <button
                    class="btn btn-success"
                    onclick={() =>
                        changePlayerData(players => [...players, createPlayer(`Spieler ${players.length + 1}`)])
                    }
                    disabled={players.length >= CONFIG.maxPlayers}
                >
                    <BTIcon icon="plus-circle" />
                    {players.length >= CONFIG.maxPlayers ? "Max. Anzahl erreicht" : "Spieler hinzufügen"}
                </button>
                <button
                    class="btn btn-danger ms-2"
                    onclick={() => {
                        if (confirm("Wirklich alle Spieler zurücksetzen?"))
                            changePlayerData(produce(players => {
                                // @ts-ignore
                                players.forEach((p: Player) => p.gameData.forEach(g => g.numCalled = g.numMade = null));
                            }));
                    }}
                >
                    <BTIcon icon="trash-fill" />
                    Reset
                </button>
                <ResultsButton onClick={showResults} />
                <SyncStateDisplay />
            </div>
            <div class="mt-2 fs-5">
                <div class="form-check form-check-inline form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="syncGameSwitch"
                        // checked={["loading", "open"].includes(syncState())}
                        checked={syncState() !== "disabled"}
                        onChange={function () {
                            setSyncState(this.checked ? "loading" : "disabled");
                            localStorage.setItem("sync_enabled", this.checked);
                        }}
                    />
                    <label class="form-check-label fs-6" for="syncGameSwitch">Spielstand synchronisieren</label>
                </div>
                <div class="form-check form-check-inline form-switch ms-2">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="saveGameSwitch"
                        checked={saveGameAfterFinished}
                        onChange={function () {
                            saveGameAfterFinished = this.checked;
                            localStorage.setItem("save_to_db", this.checked);
                        }}
                    />
                    <label class="form-check-label fs-6" for="saveGameSwitch">Spiel nach Beenden speichern</label>
                </div>
            </div>

            <GameSavedAlert
                id={savedUnderID()}
                onExit={() => setSavedID(null)}
                onRetry={() => {
                    setSavedID(null);
                    saveGameToDB();
                }} />

            <div style={{ width: "max-content" }}>
                <For each={players}>{(p, idx) =>
                    <PlayerTable isFirst={idx() === 0} player={p} />
                }</For>
                <Show when={players.length}>
                    {/* only render the mismatch display after a player has been added
                    because it needs the height of a player table to align itself on mount.
                    When the component is rendered before any players are added, there's no table 
                    and it gets the wrong height*/}
                    <InfoTable />
                </Show>
            </div>
            <ResultsButton onClick={showResults} />
        </>
    );
}
