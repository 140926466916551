import { Match, Switch, createSignal, lazy } from "solid-js";
import { render } from "solid-js/web";
import { CONFIG } from "../config";
import { GameDataMessage, Player, VersionTag } from "../types";
import { makeAPICall } from "./api";
import { PlayerDataProvider } from "./components/PlayerDataContext";
import { App } from "./pages/MainPage";

const Results = lazy(() => import("./pages/ResultsPage"));

// @ts-ignore: defined with esbuild
LIVE_RELOAD && import("./liveReload");

const initialSyncEnabled = localStorage.getItem("sync_enabled") !== null
    ? localStorage.getItem("sync_enabled") === "true"
    : CONFIG.enableSyncingByDefault;

export const LOCAL_STORAGE_KEY = "gameData";
export const [view, setView] = createSignal<"main" | "results">("main");
export const [syncState, setSyncState] = createSignal<"loading" | "open" | "disabled" | "unavailable" | "unauthorized">(initialSyncEnabled ? "loading" : "disabled");

const params = new URLSearchParams(location.search);
const debugParam = params.get("debugfill");
/** 
 * when this is true a dummy save state is generated to test the results page 
 * and the real save state in local storage isn't used
 */
export const DEBUG_DATA = debugParam && !isNaN(parseInt(debugParam));

/** tracks how many game updates were made in this session (for the version tag) */
let seqNum = 0;

saveToken();

let initalPlayerData = localStorage.getItem(LOCAL_STORAGE_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)!) : [];

render(() =>
    <PlayerDataProvider initialPlayers={initalPlayerData} debugNumPlayers={parseInt(debugParam)}>
        <Switch>
            <Match when={view() === "main"}>
                <App />
            </Match>
            <Match when={view() === "results"}>
                <Results onExit={() => setView("main")} />
            </Match>
        </Switch>
    </PlayerDataProvider>
    , document.getElementById("app") as Element);

export async function sendChanges(playerData: Player[]) {
    if (syncState() === "unauthorized" || syncState() === "disabled")
        return;

    let result: Response;

    try {
        result = await makeAPICall("/gamedata", "PUT", ({
            data: playerData,
            v: getVersionTag()
        } satisfies GameDataMessage));
    } catch (error) {
        setSyncState("unavailable");
        return;
    }

    switch (result.status) {
        case 200:
            setSyncState("open");
            break;
        case 401:
            setSyncState("unauthorized");
            break;
        case 400:
            setSyncState(playerData.length ? "unavailable" : "open");
            break;
        default:
            setSyncState("unavailable");
            break;
    }
}

function getVersionTag(): VersionTag {
    return `${Date.now()}-${seqNum++}`;
}

export async function gotoResults() {
    setView("results");

    if (syncState() !== "open")
        return;

    const token = localStorage.getItem("token");

    makeAPICall("/show-results", "POST")
}

function saveToken() {
    const url = new URL(location.href);
    const token = url.searchParams.get("token");

    if (token) {
        localStorage.setItem("token", token);
        url.searchParams.delete("token");
        location.href = url.toString();
    }
}